<template>
  <a-modal
      v-drag-modal
      title="选择公司"
      :visible="visible"
      :maskClosable="false"
      :width="1200"
      :height="700"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div style="padding:10px;">
      <div class="search">
        <!-- 搜索区域 -->
        <div class="items" style="flex: none">
          <div class="one-item" style="width: 120px">
            <a-input
                class="input"
                v-model="queryParams.uid"
                placeholder="用户ID"
                allowClear
            />
          </div>
          <div class="one-item" style="width: 120px">
            <a-select
                class="input"
                v-model="queryParams.userType"
                allowClear
                placeholder="用户类型"
                @change="search(1)"
            >
              <a-select-option
                  v-for="item in fansUserType"
                  :value="item.value"
                  :key="item.value"
              >{{ item.label }}
              </a-select-option
              >
            </a-select>
          </div>
          <div class="one-item" style="width: 120px">
            <a-select
                class="input"
                v-model="queryParams.authFlag"
                allowClear
                placeholder="认证状态"
                @change="search(1)"
            >
              <a-select-option
                  v-for="item in authFlagList"
                  :value="item.value"
                  :key="item.value"
              >{{ item.label }}
              </a-select-option
              >
            </a-select>
          </div>
          <div class="one-item" style="padding-left: 10px;width:150px">
            <a-input
                class="input"
                v-model="queryParams.username"
                placeholder="用户名"
                allowClear
            />
          </div>
<!--          <div class="one-item" style="padding-left: 10px">-->
<!--            <a-input-->
<!--                class="input"-->
<!--                v-model="queryParams.company"-->
<!--                placeholder="公司名称"-->
<!--                allowClear-->
<!--            />-->
<!--          </div>-->
          <div class="one-item" style="padding-left: 10px;width:150px">
            <a-input
                class="input"
                v-model="queryParams.mobile"
                placeholder="手机号码"
                allowClear
            />
          </div>

          <div class="one-item" style="width: 120px">
            <a-select
                class="input"
                v-model="queryParams.type"
                allowClear
                placeholder="账户类型"
                @change="search(1)"
            >
              <a-select-option
                  v-for="item in dict['userAccountType']"
                  :value="item.value"
                  :key="item.value"
              >{{ item.label }}
              </a-select-option>
            </a-select>
          </div>
<!--          <div class="one-item" style="padding-left: 10px">-->
<!--            <a-checkbox-->
<!--                class="input"-->
<!--                v-model="queryParams.idCardImage"-->
<!--                placeholder="存在身份证图片"-->
<!--                @change="search(1)"-->
<!--            >存在身份证图片-->
<!--            </a-checkbox>-->
<!--          </div>-->
        </div>
        <div class="operator">
          <a-button type="primary" @click="search(1)">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <a-table ref="TableInfo" size="small" :bordered="true"
             :columns="columns"
             rowKey="id"
             :dataSource="dataSource"
             :pagination="pagination"
             @change="handleTableChange"
             :loading="loading"
             :scroll="{y:400,x:600}">
      <template slot="images" slot-scope="text, record">
        <div v-if="text.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="authStatus" slot-scope="text, record">
        <div title="点击审核" @click.stop="auditUser(record)" style="cursor: pointer">
          <a-tag :color="record.authFlag | dictName('authStatus', 'color')" style="cursor: pointer">
            <span v-if="record.authFlag==1">去审核</span>
            <span v-else>{{ record.authFlag| dictName("authStatus") }}</span>
          </a-tag>
        </div>
      </template>

      <template slot="userType" slot-scope="text, record">
        <a-tag :color="record.userType | dictName('fansUserType', 'color')">
          {{ record.userType| dictName("fansUserType") }}
        </a-tag>
      </template>

      <template slot="type" slot-scope="text, record">
        <a-tag :color="record.type | dictName('userAccountType', 'color')">
          {{ record.type| dictName("userAccountType") }}
        </a-tag>
      </template>
      <template slot="regFrom" slot-scope="text, record">
        <a-tag :color="record.regFrom | dictName('regFrom', 'color')">{{
            record.regFrom | dictName("regFrom")
          }}
        </a-tag>
      </template>
      <template slot="company" slot-scope="text, record">
        <div><small>{{ text }}</small></div>
        <div><small>{{ record.companyInfo && record.companyInfo.bizCreditNo }}</small></div>
      </template>
      <template slot="tagStr" slot-scope="text, record">
        <div style="cursor: pointer">
          <a-tag v-if="record.tagStr" color="#FF9900" v-for="one in (record.tagStr||'').split(/,/)" :key="one"
                 mode="dark" style="cursor: pointer">
            {{
              one
            }}
          </a-tag>
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-switch :default-checked="record.status==1" disabled=""/>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" size="small" style="margin-right:15px;" @click="selectItem(record)">选择
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import {DICT} from "@/utils/dict";

export default {
  name: "SelectPosterModal",
  props: {},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "user/listByAdmin",
      visible: false,
      selected: undefined
    }
  },
  computed: {
    authFlagList() {
      return DICT['authStatus']
    },
    fansUserType() {
      return DICT['fansUserType']
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "uid",
          fixed: 'left',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'uid' && sortedInfo.order
        },
        {
          title: "头像",
          align: "center",
          width: 60,
          fixed: 'left',
          // dataIndex: "avatar",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "用户名",
          width: 150,
          align: "center",
          fixed: 'left',
          dataIndex: "username",
        },
        {
          title: "用户类型",
          width: 80,
          align: "center",
          dataIndex: "userType",
          scopedSlots: {customRender: "userType"},
        },
        // {
        //   title: "用户级别",
        //   width: 100,
        //   align: "center",
        //   dataIndex: "userLevel",
        //   sorter: true,
        //   sortOrder: sortedInfo.columnKey === 'userLevel' && sortedInfo.order
        // },

        {
          title: "账号类型",
          width: 80,
          align: "center",
          dataIndex: "type",
          scopedSlots: {customRender: "type"},
        },
        {
          title: "手机号",
          width: 100,
          align: "center",
          dataIndex: "mobile",
        },
        {
          title: "激活状态",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "status"},
        },

        {
          title: "帖子数",
          width: 100,
          align: "center",
          dataIndex: "postCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'postCount' && sortedInfo.order
        },
        {
          title: "认证状态",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "authStatus"},
        },
        {
          title: "头衔",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "tagStr"},
        },

        {
          title: "公司|律所名称",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "company",
          scopedSlots: {customRender: "company"},
        },
        {
          title: "注册时间",
          width: 150,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }]
    },
  },
  mounted() {
    this.search(1);
  },
  methods: {
    showModal() {
      this.selected = []
      this.visible = true
    },
    handleOk() {
      this.visible = false;
    },

    selectItem(item) {
      this.visible = false;
      this.$emit("onSelected", item);
    }
  }

}
</script>

<style scoped lang="less">

.step_box {
  margin: 0px auto;
  border: 1px #E5E5E5 solid;


  .step_box_head {
    border-bottom: 1px #E5E5E5 solid;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    padding: 15px 40px 15px 0;
    background: #F5F5F5;
  }

  .step_menu {
    ul li {
      float: left;
      margin-right: 20px;
      border: 0 none;
      list-style: none;
      cursor: pointer;
    }

    ul li span {
      display: block;
      background: url(/static/images/step_menu.png) left bottom no-repeat;
      font-size: 14px;
      width: 146px;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
    }

    ul li.checked span {
      background: url(/static/images/step_menu.png) left top no-repeat;
      color: #FFF;
      border: 0 none;
    }
  }
}

/deep/ .ant-modal-body {
  padding: 0 0px;
}
</style>
